<template>
  <div class="neo-home-flat">
    <div class="neo-home-flat__content">
      <h2 class="heading-underlined heading-size-3 mb-8">{{ title }}</h2>
      <div class="neo-home-flat__grid" :style="cssVars">
        <EmergingArtistCard
          :path-link="pathForCards"
          v-for="card in cards"
          :key="card._id"
          :card="card"
          :id="card._id"
          tiny
        />
      </div>
    </div>
  </div>
</template>

<script>
import EmergingArtistCard from '@/components/Organisms/EmergingArtistCard';

export default {
  name: 'EmergingArtists',
  components: {
    EmergingArtistCard,
  },
  props: {
    cardsPerRow: Number,
    pathForCards: {
      type: String,
      default: 'work',
    },
    cards: Array,
    title: String,
    color: String,
  },
  data: () => ({
    hover: false,
  }),
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        '--cards-per-row': this.cardsPerRow,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.neo-home-flat {
  padding: 12px 12px 64px;
  background: var(--color-grey-dark);
  &:nth-child(2n) {
    background: var(--color-grey);
  }
  @media only screen and (min-width: 1240px) {
    padding: 24px 24px 64px;
  }
  &__content {
    max-width: var(--main-width-container);
    margin: 0 auto;
  }
  &__grid {
    display: grid;
    justify-content: space-around;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(auto, 400px));
    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(var(--cards-per-row), 1fr);
    }
  }
}
</style>
