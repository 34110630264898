<template>
  <div>
    <EmergingArtists
      v-if="featured.length > 0"
      :cards="featured"
      title="Featured"
      :cardsPerRow="3"
    /> 
    <EmergingArtists
      :cards="emerging"
      title="Artists"
      :cardsPerRow="4"
    /> 
  </div>
</template>

<script>
import EmergingArtists from "@/components/Templates/EmergingArtists";

import EmergingArtistDS from "@/services/EmergingArtistDS";

export default {
  name: "vEmergingArtists",
  components: {
    EmergingArtists,
  },
  data: () => ({
    emerging: [],
    resell: [],
    featured: [],
    preprod: [],
  }),
  beforeMount() {
    EmergingArtistDS.getPublishedFS().then((artists) => {
      this.emerging = artists;
    });
    
    // this.emerging = [{
    //   _id: '123456',
    //   artist: 'Sisti Franz',
    //   birthCountry: 'Japan',
    //   year: '1935',
    //   works: 4,
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   _id: '1234561',
    //   artist: 'Sisti Franz',
    //   birthCountry: 'Japan',
    //   year: '1935',
    //   works: 3,
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   _id: '1234562',
    //   artist: 'Sisti Franz',
    //   birthCountry: 'Japan',
    //   year: '1935',
    //   works: 5,
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   _id: '1234563',
    //   artist: 'Sisti Franz',
    //   birthCountry: 'Japan',
    //   year: '1935',
    //   works: 1,
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   _id: '1234564',
    //   artist: 'Sisti Franz',
    //   birthCountry: 'Japan',
    //   year: '1935',
    //   works: 3,
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // }]

    
    // .then((results) => {
    //   this.featured = results.featured;
    //   this.firstRound = results.firstRound;
    // });
  },
};
</script>
